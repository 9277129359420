@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
#app {
  overflow-x: hidden;
}
img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
/* In your global CSS file or styled-components */
@media (max-width: 640px) {
  body {
    padding-bottom: 4rem; /* Adjust this value based on your mobile menu height */
  }
}
